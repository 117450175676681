.container {
  background: linear-gradient(90deg, #37e6e6 0%, #1a7f72 100%);
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-height: 150px;
  gap: 60px;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
    max-height: unset;
  }
}

.linkWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 36px;
  font-weight: 600;
  line-height: 54px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
}

.imageWrapper {
  margin-top: -105px;
  z-index: 1;
  @media (max-width: 767px) {
    margin-top: 0;
    max-width: 100%;
  }
}
