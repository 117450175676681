.snowLayout {
  width: 100%;
  overflow: hidden;
  .itemLeft {
    position: absolute;
    left: 0;
    top: 0;
  }
  .itemRight {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.lineLayout {
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: -220px;
  z-index: 0;
}

.lineLayoutV2 {
  overflow: hidden;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.arrowsLayout {
  overflow: hidden;
  width: 100%;
  .arrowRight {
    position: absolute;
    right: 10%;
  }
  .arrowLeft {
    position: absolute;
    left: 10%;
  }
}
