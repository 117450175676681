.form {
  display: flex;
  flex-direction: column;
  padding: 64px 60px;
  border-radius: 20px;
  // box-shadow: 0px 20px 30px 0px #0000000d;
  background: white;
  row-gap: 8px;
  width: 100%;
  max-width: 1165px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 20px; // Для мобильных устройств
  }
}

.input {
  height: 68px !important;
  border-radius: 12px !important;
  border-width: 0.5px !important;
  width: 100% !important;
}

.input::placeholder {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  color: rgba(79, 79, 79, 1) !important;
  opacity: 0.7 !important;
}
.label {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  text-align: left !important;
  width: 100% !important;
  display: block !important;
}
