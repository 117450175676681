.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 184px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}
.childrenWrapper {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
