.iconWrapper {
  background: #feecd5;
  border-radius: 12px;
  width: 74px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
