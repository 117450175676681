.borderedBlock {
  border: 1px solid #4f4f4f;
  width: 100%;
  height: 100%;
  min-height: 390px;
  border-radius: 12px;
  opacity: 50%;
  position: absolute;
  top: 20px;
  right: 0px;
  z-index: 0;
}

.bannerInner {
  padding: 20px;
  border-radius: 12px;
  background: linear-gradient(
    228.65deg,
    rgba(252, 161, 44, 0.4) 1.84%,
    rgba(252, 161, 44, 0) 61.04%
  );
}

.banner {
  border-radius: 12px;
  bottom: 0;
  z-index: 30;
  background-color: antiquewhite;
  min-width: 330px;
  min-height: 200px;
  bottom: -482px;
  left: -50px;
}
