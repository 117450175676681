.list {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 20px;
  }
}

.listItem {
  height: auto;
}
