.article {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 40px;
}

.priceBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
}

.price {
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  color: #fca12c;
}
