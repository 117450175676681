.article {
  display: flex;
  // align-items: center;
  gap: 20px;
}
.heading {
  color: #4f4f4f;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
}

.imgWrapper {
  flex: 0 0 140px;
  height: 140px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 768px) {
    flex: 0 0 120px;
    height: 120px;
  }
}
