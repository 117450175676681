.articles {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding-top: 50px;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
  }
}

.articlesSmall {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  align-self: stretch;
  gap: 10px;

  @media (max-width: 1200px) {
    align-items: center;
  }
}

.articlesBig {
  width: 100%;

  @media (max-width: 1200px) {
    width: 100%;
  }
}
