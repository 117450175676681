.label {
  padding: 2px 18px;
  gap: 10px;
  border-radius: 6px;
  border: 2px solid #fca12c;
  border-top: none;
  border-bottom: none;
  background: #fff5e9;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  width: fit-content;
}
