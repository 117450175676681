.wrapper {
  max-width: 410px;
  box-shadow: 4px 16px 50px 0px #0000000d;
  border-radius: 20px;
  height: 100%;
}
.iconWrapper {
  margin-bottom: 16px;
}
.image {
  border-radius: 20px 20px 0 0;
}
