.wrapper {
  border-radius: 20px;
  position: relative;
  padding: 100px 60px;
  width: 100%;

  @media (max-width: 1200px) {
    padding: 40px 20px;
    text-align: center; // Центрируем текст на мобильных
  }
}

.imageWrapper {
  position: absolute;
  right: 100px;
  top: -45%;

  @media (max-width: 1200px) {
    position: relative;
    top: unset;
    right: unset;
    margin-top: 20px; // Добавляем отступ сверху для изображения
    display: flex;
    justify-content: center;
  }
}

.btnBlock {
  display: grid;
  grid-template-columns: 500px 300px;
  align-items: end;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    gap: 20px; // Добавляем расстояние между текстом и кнопкой
  }
}
