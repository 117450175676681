.bgTextWrapper {
  position: relative;
}

.bgTextWrapperInner {
  position: absolute;
  background-color: var(--main-orange);
  width: 313px;
  height: 93px;
  top: -6px;
  z-index: 1;
  transform: rotate(-2deg);
  left: -34px;
}

.bgTextWrapperInnerBlack {
  position: absolute;
  background-color: var(--main-black);
  width: 307px;
  height: 10px;
  bottom: -89px;
  z-index: 0;
  transform: rotate(1deg);
  left: -27px;
}

.carWrapper {
  top: -18px;
}
.gradientWrapperBg {
  top: -80px;
  left: -100px;
}

.text {
  position: absolute;
  z-index: 2;
}
