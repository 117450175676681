.article {
  position: relative;
}

.overlay {
  position: absolute;
  top: 30%;
  left: 15%;
  background-color: white;
  border-radius: 16px;
  max-width: 520px;
  width: 100%;

  @media (max-width: 768px) {
    left: 0;
  }
}

.overlayInner {
  border-radius: 16px;
  padding: 30px;
  background: linear-gradient(
    228.65deg,
    rgba(252, 161, 44, 0.4) 1.84%,
    rgba(252, 161, 44, 0) 61.04%
  );

  @media (max-width: 768px) {
    padding: 10px;
  }
}

.heading {
  color: #4f4f4f;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
}

.imgWrapper {
  max-width: 520px;
  height: 460px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
