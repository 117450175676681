.content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  gap: 78px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 24px; // уменьшаем отступ между колонками
  }
}

.contentLeft {
  width: 440px;
  position: sticky;
  top: 10px;

  @media (max-width: 768px) {
    width: 100%; // на мобильных 100% ширины
    position: relative; // убираем фиксированное позиционирование
    top: auto; // убираем отступ сверху
  }
}

.contentRight {
  width: 100%;

  @media (max-width: 768px) {
    width: 100%; // на мобильных 100% ширины
  }
}
