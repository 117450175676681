.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.carWrapper {
  top: -18px;
  left: 0;
}

.gradientWrapperBg {
  top: -80px;
  left: -100px;
}

.bgTextWrapper {
  position: relative;
}

.bgTextWrapperInner {
  position: absolute;
  background-color: var(--main-orange);
  width: 313px;
  height: 93px;
  top: -6px;
  z-index: -1;
  transform: rotate(-2deg);
  left: -34px;
}

.bgTextWrapperInnerBlack {
  position: absolute;
  background-color: var(--main-black);
  width: 307px;
  height: 10px;
  bottom: -15px;
  z-index: -2;
  transform: rotate(1deg);
  left: -27px;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 10px;
  }

  .carWrapper {
    width: 100%;
    top: 0;
    right: 0;
  }

  .gradientWrapperBg {
    top: -40px;
    left: -50px;
  }

  .bgTextWrapperInner {
    width: 200px; // Consider adjusting this if needed
    height: 60px;
  }

  .bgTextWrapperInnerBlack {
    width: 180px; // Consider adjusting this if needed
    height: 8px;
  }
}
