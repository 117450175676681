.contaner {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 24px;
}

.item {
  min-height: 36px;
  padding: 0 16px 0 32px;
  background: #fca12c;
  width: 100%;
  opacity: 88%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.iconWrapper {
  border: 1px solid #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.subMenu {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  max-height: 0;
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-left: 30px;
}

.open {
  max-height: 500px;
  opacity: 1;
}

.closed {
  max-height: 0;
  opacity: 0;
}

.subMenuItem {
  width: 100%;
  display: flex;
  align-items: center;
  // background: #4f4f4f;
  opacity: 88%;
  border-radius: 12px;
  padding: 0px 32px;
  min-height: 36px;
  cursor: pointer;

  margin-bottom: 8px;
}
