.form {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  border-radius: 20px;
  // box-shadow: 0px 20px 30px 0px #0000000d;
  background: white;
  row-gap: 8px;
  width: 100%;
  max-width: 1165px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 20px; // Для мобильных устройств
  }
}

.input {
  height: 68px;
  border-radius: 12px;
  border-width: 0.5px;
  width: 100%;
}

.input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: rgba(79, 79, 79, 1);
  opacity: 0.7;
}
.label {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  width: 100%;
  display: block;
}
