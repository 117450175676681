.wrapper {
  padding: 32px;
  border-radius: 12px;
  box-shadow: 8px 16px 50px 0px #0000000d;
  background: linear-gradient(
    228.65deg,
    rgba(252, 161, 44, 0.4) 1.84%,
    rgba(252, 161, 44, 0) 61.04%
  );
  text-align: center;
}

.wrapperInner {
}
